import { Link } from "react-router-dom";
import blueLogo from "../../assets/Logo-blue.png";

import "./Navbar.css";

function Navbar() {
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary navbar-color">
        <div className="container-fluid navbar-color">
          <Link to="/" className="navbar-brand">
            <img src={blueLogo} alt="SilaLogo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link to="/" className="nav-link active" aria-current="page">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="#"
                  className="nav-link"
                  onClick={() =>
                    window.scrollTo({
                      top: document.getElementById("Units").offsetTop,
                      behavior: 'smooth',
                    })
                  }
                >
                  Units
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
