import "./PropertyData.css";

function PropertyData() {
  return (
    <>
      <div className="propertyData-backColor">
        <div className="PropertyData__container ">
          <div className="PropertyData__container-construction">
            <div className="PropertyData__container-construction-svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 64 64"
                id="Apartment"
              >
                <path
                  d="M41.67 62.5H22.33a1.5 1.5 0 0 1-1.5-1.5V3a1.5 1.5 0 0 1 1.5-1.5h19.34a1.5 1.5 0 0 1 1.5 1.5v58a1.5 1.5 0 0 1-1.5 1.5Zm-17.84-3h16.34v-55H23.83Z"
                  fill="#0061e0"
                  className="color000000 svgShape"
                ></path>
                <path
                  d="M32 62.5h-5.37a1.5 1.5 0 0 1-1.5-1.5v-9a1.5 1.5 0 0 1 1.5-1.5H32a1.5 1.5 0 0 1 1.5 1.5v9a1.5 1.5 0 0 1-1.5 1.5Zm-3.87-3h2.37v-6h-2.37Z"
                  fill="#0061e0"
                  className="color000000 svgShape"
                ></path>
                <path
                  d="M37.38 62.5H32a1.5 1.5 0 0 1-1.5-1.5v-9a1.5 1.5 0 0 1 1.5-1.5h5.38a1.5 1.5 0 0 1 1.5 1.5v9a1.5 1.5 0 0 1-1.5 1.5zm-3.88-3h2.38v-6H33.5zm27.5 3H41.67a1.5 1.5 0 0 1-1.5-1.5V38.31a1.5 1.5 0 0 1 1.5-1.5H61a1.5 1.5 0 0 1 1.5 1.5V61a1.5 1.5 0 0 1-1.5 1.5zm-17.83-3H59.5V39.81H43.17zm-20.84 3H3A1.5 1.5 0 0 1 1.5 61V20.75a1.51 1.51 0 0 1 2.05-1.4L22.88 27a1.5 1.5 0 0 1 .95 1.4V61a1.5 1.5 0 0 1-1.5 1.5zm-17.83-3h16.33V29.39L4.5 23z"
                  fill="#0061e0"
                  className="color000000 svgShape"
                ></path>
                <path
                  d="M16.92 45.6h-8.5a1.5 1.5 0 0 1 0-3h8.5a1.5 1.5 0 0 1 0 3zm0-7.11h-8.5a1.5 1.5 0 0 1 0-3h8.5a1.5 1.5 0 0 1 0 3zm19.33-28.43h-8.5a1.5 1.5 0 1 1 0-3h8.5a1.5 1.5 0 0 1 0 3zm0 14.21h-8.5a1.5 1.5 0 0 1 0-3h8.5a1.5 1.5 0 0 1 0 3zm0-7.1h-8.5a1.5 1.5 0 1 1 0-3h8.5a1.5 1.5 0 0 1 0 3zm0 21.32h-8.5a1.5 1.5 0 0 1 0-3h8.5a1.5 1.5 0 0 1 0 3zm0 7.11h-8.5a1.5 1.5 0 1 1 0-3h8.5a1.5 1.5 0 0 1 0 3zm19.33 0h-8.5a1.5 1.5 0 0 1 0-3h8.5a1.5 1.5 0 0 1 0 3zM36.25 31.38h-8.5a1.5 1.5 0 0 1 0-3h8.5a1.5 1.5 0 0 1 0 3z"
                  fill="#0061e0"
                  className="color000000 svgShape"
                ></path>
              </svg>
            </div>
            <div className="PropertyData__container-construction-num">
              <h3>+10</h3>
            </div>
            <div className="PropertyData__container-construction-title">
              <p className="info-color">Residential Building</p>
            </div>
          </div>
          <div className="PropertyData__container-construction">
            <div className="PropertyData__container-construction-svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 40 40"
                id="key"
              >
                <path
                  d="M9.4 29.8c-2.4 0-4.9-.9-6.7-2.8-3.7-3.7-3.7-9.8 0-13.5 3.3-3.3 8.5-3.7 12.3-1 .4.3.5 1 .2 1.4-.3.4-.9.5-1.4.2-3-2.2-7.1-1.9-9.7.8-2.9 2.9-2.9 7.7 0 10.6 2.9 2.9 7.7 2.9 10.6 0 .1-.1.2-.2.3-.4.4-.4 1-.5 1.4-.1.4.4.5 1 .1 1.4-.1.2-.3.3-.4.4-1.9 2.1-4.3 3-6.7 3z"
                  fill="#0061e0"
                  className="color000000 svgShape"
                ></path>
                <path
                  d="M9.4 24.6c-1.1 0-2.2-.4-3-1.3-.8-.8-1.3-1.9-1.3-3s.4-2.2 1.3-3c.8-.8 1.9-1.3 3-1.3s2.2.4 3 1.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0-.9-.9-2.4-.9-3.2 0-.4.4-.7 1-.7 1.6 0 .6.2 1.2.7 1.6.9.9 2.4.9 3.2 0 .4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4-.8.9-1.9 1.3-3 1.3zM36 25.8c-1.4 0-2.1-.8-2.6-1.3s-.7-.7-1.2-.7-.7.2-1.2.7c-.5.6-1.2 1.3-2.6 1.3-1.4 0-2.1-.8-2.6-1.3s-.7-.7-1.2-.7-.7.2-1.2.7c-.5.6-1.2 1.3-2.6 1.3-1.4 0-2.1-.8-2.6-1.3-.4-.4-.6-.6-.9-.7-.5-.1-.9-.7-.8-1.2s.7-.9 1.2-.8c.9.2 1.5.8 1.9 1.2.5.5.7.7 1.2.7s.7-.2 1.2-.7c.5-.6 1.2-1.3 2.6-1.3 1.4 0 2.1.8 2.6 1.3s.7.7 1.2.7.7-.2 1.2-.7c.5-.6 1.2-1.3 2.6-1.3 1.4 0 2.1.8 2.6 1.3.3.3.5.5.7.6l2.3-3.4-2.3-3.5H16.6c-.6 0-1-.4-1-1s.4-1 1-1H36c.3 0 .6.2.8.4l3 4.5c.2.3.2.8 0 1.1l-3 4.5c-.2.5-.5.6-.8.6z"
                  fill="#0061e0"
                  className="color000000 svgShape"
                ></path>
              </svg>
            </div>
            <div className="PropertyData__container-construction-num">
              <h3>+80</h3>
            </div>
            <div className="PropertyData__container-construction-title">
              <p className="info-color">Unit</p>
            </div>
          </div>
          <div className="PropertyData__container-construction">
            <div className="PropertyData__container-construction-svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                enableBackground="new 0 0 512 512"
                viewBox="0 0 512 512"
                id="Location"
              >
                <path
                  d="M444.1,79.3H275.5C270.7,26,228.4-0.9,187.6,0c-40.7-0.9-83,25.9-87.9,79.3H67.9c-15.6,0-28.3,12.7-28.3,28.3v376.2
	c0,15.6,12.7,28.3,28.3,28.3h376.2c15.6,0,28.3-12.7,28.3-28.3V107.6C472.3,92,459.7,79.3,444.1,79.3z M456.3,107.6v376.2
	c0,0.3,0,0.6,0,0.9l-189-189l189-189C456.3,107,456.3,107.3,456.3,107.6z M115.3,85.5c1.9-45.9,36.5-69.5,70.2-69.5
	c0.6,0,1.2,0,1.9,0c0.1,0,0.3,0,0.4,0c34.3-0.9,70.1,22.7,72.1,69.5c1.9,44.8-37,90.8-65.4,124.4c-2.4,2.8-4.7,5.6-6.9,8.2
	c-2.2-2.7-4.6-5.4-6.9-8.2C152.3,176.3,113.4,130.3,115.3,85.5z M55.7,107.6c0-6.8,5.5-12.3,12.3-12.3h31.6
	c3.3,47.2,41,91.7,69,124.9c4.6,5.5,9,10.7,13,15.5c1.5,1.9,3.8,3,6.2,3s4.7-1.1,6.2-3c3.9-4.9,8.3-10,13-15.5
	c28-33.1,65.6-77.7,69-124.9h168.3c0.3,0,0.6,0,0.9,0L55.7,484.6c0-0.3,0-0.6,0-0.9V107.6z M67.9,496c-0.3,0-0.6,0-0.9,0l189-189
	l189,189c-0.3,0-0.6,0-0.9,0H67.9z M227.9,87.3c0-22.2-18.1-40.3-40.3-40.3s-40.3,18.1-40.3,40.3s18.1,40.3,40.3,40.3
	S227.9,109.5,227.9,87.3z M163.4,87.3c0-13.4,10.9-24.3,24.3-24.3s24.3,10.9,24.3,24.3s-10.9,24.3-24.3,24.3S163.4,100.7,163.4,87.3
	z"
                  fill="#0061e0"
                  className="color000000 svgShape"
                ></path>
              </svg>
            </div>
            <div className="PropertyData__container-construction-num">
              <h3>+21000</h3>
            </div>
            <div className="PropertyData__container-construction-title">
              <p className="info-color">Total Area sq</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PropertyData;
