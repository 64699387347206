import threeRoomMasterPlan1 from "../../assets/threeRoomMasterPlan1.png";
import threeRoomMasterPlan2 from "../../assets/threeRoomMasterPlan2.png";
import threeRoomMasterPlan3 from "../../assets/threeRoomMasterPlan3.png";
import threeRoomMasterPlan4 from "../../assets/threeRoomMasterPlan4.png";
import threeRoomMasterPlan5 from "../../assets/threeRoomMasterPlan5.png";
import threeRoomMasterPlan6 from "../../assets/threeRoomMasterPlan6.png";
import threeRoomMasterPlan7 from "../../assets/threeRoomMasterPlan7.png";
import threeRoomMasterPlan8 from "../../assets/threeRoomMasterPlan8.png";
import threeRoomMasterPlan9 from "../../assets/threeRoomMasterPlan9.png";
import threeRoomMasterPlan10 from "../../assets/threeRoomMasterPlan10.png";
import threeRoomMasterPlan11 from "../../assets/threeRoomMasterPlan11.png";
import threeRoomMasterPlan12 from "../../assets/threeRoomMasterPlan12.png";
import threeRoomMasterPlan13 from "../../assets/threeRoomMasterPlan13.png";
import threeRoomMainBath1 from "../../assets/threeRoomMainBath1.png";
import threeRoomMainBath2 from "../../assets/threeRoomMainBath2.png";
import threeRoomMainBath3 from "../../assets/threeRoomMainBath3.png";
import threeRoomMainBath4 from "../../assets/threeRoomMainBath4.png";
import threeRoomMasterBedroom1 from "../../assets/threeRoomMasterBedroom1.png";
import threeRoomMasterBedroom2 from "../../assets/threeRoomMasterBedroom2.png";
import threeRoomMasterBedroom3 from "../../assets/threeRoomMasterBedroom3.png";
import threeRoomMasterBedroom4 from "../../assets/threeRoomMasterBedroom4.png";
import threeRoomMasterBedroom5 from "../../assets/threeRoomMasterBedroom5.png";
import threeRoomMasterBedroom6 from "../../assets/threeRoomMasterBedroom6.png";
import threeRoomMasterPath1 from "../../assets/threeRoomMasterPath1.png";
import twoBedromMasterBath2 from "../../assets/twoBedromMasterBath2.png";
import twoBedromMasterBath3 from "../../assets/twoBedromMasterBath3.png";
import twoBedromMasterBath4 from "../../assets/twoBedromMasterBath4.png";
import threeRoomChildRoom1 from "../../assets/threeRoomChildRoom1.png";
import twoBedromChildRom2 from "../../assets/twoBedromChildRom2.png";
import twoBedromChildRom3 from "../../assets/twoBedromChildRom3.png";
import twoBedromChildRom4 from "../../assets/twoBedromChildRom4.png";
import twoBedromChildRom5 from "../../assets/twoBedromChildRom5.png";
import twoBedromChildRom6 from "../../assets/twoBedromChildRom6.png";
import twoBedromChildRom7 from "../../assets/twoBedromChildRom7.png";
import threeBedroomFile from "../../assets/ThreeBedroom.pdf";

function CardThree() {
  return (
    <>
      {/* Three bedroom */}
      <div className="PaymentUnitInfo__oneBedroom">
        <h1 className="PaymentUnitInfo__h1 secondary-color flex__center">
          Three Bedroom
        </h1>

        {/* card */}

        <div
          id="carouselExampleRide"
          className="carousel slide carusel__img"
          data-bs-ride="true"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src={threeRoomMasterPlan1}
                className="d-block w-100"
                alt="..."
              />
            </div>
            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={threeRoomMasterPlan2}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={threeRoomMasterPlan3}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={threeRoomMasterPlan4}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={threeRoomMasterPlan5}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={threeRoomMasterPlan6}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={threeRoomMasterPlan7}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={threeRoomMasterPlan8}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={threeRoomMasterPlan9}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={threeRoomMasterPlan10}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={threeRoomMasterPlan11}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={threeRoomMasterPlan12}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={threeRoomMasterPlan13}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={threeRoomMainBath1}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={threeRoomMainBath2}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={threeRoomMainBath3}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={threeRoomMainBath4}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={threeRoomMasterBedroom1}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={threeRoomMasterBedroom2}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={threeRoomMasterBedroom3}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={threeRoomMasterBedroom4}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={threeRoomMasterBedroom5}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={threeRoomMasterBedroom6}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={threeRoomMasterPath1}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={twoBedromMasterBath2}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={twoBedromMasterBath3}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={twoBedromMasterBath4}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={threeRoomChildRoom1}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={twoBedromChildRom2}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={twoBedromChildRom3}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={twoBedromChildRom4}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={twoBedromChildRom5}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={twoBedromChildRom6}
                className="d-block w-100"
                alt="..."
              />
            </div>

            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={twoBedromChildRom7}
                className="d-block w-100"
                alt="..."
              />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleRide"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleRide"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        {/* dowload file */}

        <div className="OneBedroom__downloadFile ">
          <a
            className="flex__center"
            href={threeBedroomFile}
            download="Sila Three Bedroom"
            target="_blank"
            rel="noreferrer"
          >
            <button type="button" className="btn btn-primary Downolad-btn">
              Download File
            </button>
          </a>
        </div>

        {/* info */}
        <div className="PaymentUnitInfo__oneBedroom-info ">
          <h3 className="flex__center secondary-color">Info</h3>
          <div className="info-grid">
            <div className="info-item">
              <span className="main-span">Unit Area:</span> 160 Square Meter
            </div>
            <div className="info-item">
              <span className="main-span">Bedrooms:</span> 3 Bedroom
            </div>
            <div className="info-item">
              <span className="main-span">Price:</span> 30,000 For Meter
            </div>
            <div className="info-item">
              <span className="main-span">Payment Method:</span> Cash /
              Installment
            </div>

            <div className="info-item">
              <span className="main-span">Unit Total Price:</span> 4,800,000 EGP
            </div>

            <div className="info-item">
              <span className="main-span">Unit Cash Price:</span> 3,840,000 EGP
            </div>

            <div className="info-item">
              <span className="main-span">Unit Installment advance:</span>{" "}
              240,000 for booking <br></br>{" "}
              <span className="flex__center">and 480,000 after contract </span>
            </div>

            <div className="info-item">
              <span className="main-span">Unit Quarterly installment Price:</span>{" "}
              340,000 EGP
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CardThree;
