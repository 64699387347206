import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import "./Gallery.css";
import React from "react";


import gallery1 from "../../assets/gallery/1.jpg";
import gallery2 from "../../assets/gallery/gallery (2).jpg";
import gallery3 from "../../assets/gallery/gallery (3).jpg";
import gallery4 from "../../assets/gallery/gallery (4).jpg";
import gallery5 from "../../assets/gallery/gallery (5).jpg";
import gallery6 from "../../assets/gallery/gallery (11).jpg";
import gallery7 from "../../assets/gallery/gallery (7).jpg"; 
import gallery8 from "../../assets/gallery/gallery (8).jpg";
import gallery9 from "../../assets/gallery/gallery (9).jpg";
import gallery10 from "../../assets/gallery/gallery (10).jpg";
import gallery11 from "../../assets/gallery/gallery (11).jpg";
import gallery12 from "../../assets/gallery/gallery (12).jpg";
import gallery13 from "../../assets/gallery/gallery (13).jpg";
import gallery14 from "../../assets/gallery/gallery (14).jpg";
import gallery15 from "../../assets/gallery/gallery (15).jpg";
import gallery16 from "../../assets/gallery/gallery (16).jpg";
import gallery17 from "../../assets/gallery/gallery (17).jpg";
import gallery18 from "../../assets/gallery/gallery (18).jpg";
import gallery19 from "../../assets/gallery/gallery (19).jpg";
import gallery20 from "../../assets/gallery/gallery (20).jpg";
import gallery21 from "../../assets/gallery/gallery (21).jpg";
import gallery22 from "../../assets/gallery/gallery (22).jpg";
import gallery23 from "../../assets/gallery/gallery (23).jpg";
import gallery24 from "../../assets/gallery/gallery (24).jpg";
import gallery25 from "../../assets/gallery/gallery (25).jpg";
import gallery26 from "../../assets/gallery/gallery (26).jpg";
import gallery27 from "../../assets/gallery/gallery (27).jpg";
import gallery28 from "../../assets/gallery/gallery (28).jpg";
import gallery29 from "../../assets/gallery/gallery (29).jpg";
import gallery30 from "../../assets/gallery/gallery (30).jpg";
import gallery32 from "../../assets/gallery/gallery (32).jpg";
import gallery33 from "../../assets/gallery/gallery (33).jpg";
import gallery34 from "../../assets/gallery/gallery (34).jpg";
import gallery35 from "../../assets/gallery/gallery (35).jpg";
import gallery36 from "../../assets/gallery/gallery (36).jpg";
import gallery37 from "../../assets/gallery/gallery (37).jpg";
import gallery38 from "../../assets/gallery/gallery (38).jpg";
import gallery39 from "../../assets/gallery/gallery (39).jpg";
import gallery40 from "../../assets/gallery/gallery (40).jpg";


function Gallery() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="gallery__container">
      <p className="PaymentDetails__ourPaymentDetails flex__center main-color gallery-p">
        GALLERY
      </p>
      <div className="gallery__title center-main-add secondary-color">
        <h1>Take A Close Look</h1>
      </div>
      <div className="gallery__image-contaienr">
        <div className="gallery__img">
          <Carousel responsive={responsive} className="flex__center">
            <div>
              <img src={gallery1} alt="Gallery 1" />
            </div>
            <div>
              <img src={gallery2} alt="Gallery 2" />
            </div>
            <div>
              <img src={gallery3} alt="Gallery 3" />
            </div>
            <div>
              <img src={gallery4} alt="Gallery 4" />
            </div>
            <div>
              <img src={gallery5} alt="Gallery 5" />
            </div>
            <div>
              <img src={gallery6} alt="Gallery 6" />
            </div>
            <div>
              <img src={gallery7} alt="Gallery 7" />
            </div>
            <div>
              <img src={gallery8} alt="Gallery 8" />
            </div>
            <div>
              <img src={gallery9} alt="Gallery 9" />
            </div>
            <div>
              <img src={gallery10} alt="Gallery 10" />
            </div>
            <div>
              <img src={gallery11} alt="Gallery 11" />
            </div>
            <div>
              <img src={gallery12} alt="Gallery 12" />
            </div>
            <div>
              <img src={gallery13} alt="Gallery 13" />
            </div>
            <div>
              <img src={gallery14} alt="Gallery 14" />
            </div>
            <div>
              <img src={gallery15} alt="Gallery 15" />
            </div>
            <div>
              <img src={gallery16} alt="Gallery 16" />
            </div>
            <div>
              <img src={gallery17} alt="Gallery 17" />
            </div>
            <div>
              <img src={gallery18} alt="Gallery 18" />
            </div>
            <div>
              <img src={gallery19} alt="Gallery 19" />
            </div>
            <div>
              <img src={gallery20} alt="Gallery 20" />
            </div>
            <div>
              <img src={gallery21} alt="Gallery 21" />
            </div>
            <div>
              <img src={gallery22} alt="Gallery 22" />
            </div>
            <div>
              <img src={gallery23} alt="Gallery 23" />
            </div>
            <div>
              <img src={gallery24} alt="Gallery 24" />
            </div>
            <div>
              <img src={gallery25} alt="Gallery 25" />
            </div>
            <div>
              <img src={gallery26} alt="Gallery 26" />
            </div>
            <div>
              <img src={gallery27} alt="Gallery 27" />
            </div>
            <div>
              <img src={gallery28} alt="Gallery 28" />
            </div>
            <div>
              <img src={gallery29} alt="Gallery 29" />
            </div>
            <div>
              <img src={gallery30} alt="Gallery 30" />
            </div>
            <div>
              <img src={gallery32} alt="Gallery 32" />
            </div>
            <div>
              <img src={gallery33} alt="Gallery 33" />
            </div>
            <div>
              <img src={gallery34} alt="Gallery 34" />
            </div>
            <div>
              <img src={gallery35} alt="Gallery 35" />
            </div>
            <div>
              <img src={gallery36} alt="Gallery 36" />
            </div>
            <div>
              <img src={gallery37} alt="Gallery 37" />
            </div>
            <div>
              <img src={gallery38} alt="Gallery 38" />
            </div>
            <div>
              <img src={gallery39} alt="Gallery 39" />
            </div>
            <div>
              <img src={gallery40} alt="Gallery 40" />
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
