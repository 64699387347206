// we need another photos for another unit like B that Mahmoud had send it

//importing photos here.....
//               ONE BEDROOM

//               TWO BEDROOMS

//               THREE BEDROOMS

// any thing now to do a card with main img
import img1 from "../src/assets/1.jpg";
import img2 from "../src/assets/2.jpg";
import img3 from "../src/assets/3.jpg";
const Card_data = [
  {
    id: 1,
    mainImg: img1,
    title: "Single Room",
    bedroomNumber: 1,
    bathroomsNumber: 1,
    sizeUnit: "75 Square Meters",
    images: ["", ""],
  },

  {
    id: 2,
    mainImg: img2,
    title: "Double Rooms",
    bedroomNumber: 2,
    bathroomsNumber: 2,
    sizeUnit: "115 Square Meters",
    images: ["", ""],
  },

  {
    id: 3,
    mainImg: img3,
    title: "Triple Rooms",
    bedroomNumber: 3,
    bathroomsNumber: 2,
    sizeUnit: "160 Square Meters",
    images: ["", ""],
  },
];

export default Card_data;
