import CardThree from "../components/PaymentUnitInfo/CardThree";
import PaymentUnitInfo from "../components/PaymentUnitInfo/PaymentUnitInfo";

function PaymentDetailsPage() {
  return (
    <>
      <PaymentUnitInfo />
      <CardThree />
    </>
  );
}

export default PaymentDetailsPage;
