import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card_data from "../../Card-data";

function UnitDetails() {
  const params = useParams();
  const [product, setProduct] = useState([]);

  useEffect(() => {
    fetch(`${"https://fakestoreapi.com/products"}/${params.UnitID}`)
      .then((res) => res.json())
      .then((product) => setProduct(product))
      .catch((error) => console.error("Error fetching product:", error));
  }, []);
console.log(product);
// console.log(Card_data);
  return (
    <>
      <h1>Product Details</h1>
      {product ? (
        <div>
          <h2>{product.id}</h2>
          <p>{product.title}</p>
          {/* <p>Price: ${product.bedroomNumber}</p> */}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
}

export default UnitDetails;
