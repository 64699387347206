import Footer from "../components/Footer/Footer";
// import sedemail 
function FooterRoute() {
  return (
    <>

      <Footer />
    </>
  );
}

export default FooterRoute;
